import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/';
import BackgroundImage from 'gatsby-background-image/';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Grid } from '@material-ui/core';
import FONT from '../../constants/fonts';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import {
  arrayOf,
  shape,
  oneOf,
  string,
  bool,
} from 'prop-types';
import Header from '../Header';
import {
  setTextPosition,
  setBackgroundPosition,
} from '../../utils/banner-slider';
import SIZES from '../../constants/sizes';
import Youtube, {
  getVideoThumbnail,
} from '../Video/Youtube';
import { Link } from 'gatsby-plugin-intl';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const DEFAULT_BACKGROUND_COLOR = COLORS.DARK_BLUE;
const DEFAULT_TEXT_COLOR = COLORS.WHITE;

const useStyles = makeStyles(theme => ({
  slideImage: {
    height: ({ height }) => height,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '200px 200px 150px',
    [theme.breakpoints.down('lg')]: {
      padding: '140px 100px 130px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '150px 30px 80px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '100px 50px 60px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '100px 0 60px',
    },
  },
  contentContainer: {
    zIndex: 2,
    display: 'flex',
    height: 'fit-content',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    '&>*': {
      direction: 'ltr !important',
      textAlign: 'left !important',
      order: '0 !important',
      flexBasis: '45% !important',
      margin: '0 !important',
      alignSelf: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      height: '100%',
      '&>*': {
        flexBasis: '100% !important',
        alignSelf: 'auto',
      },
    },
  },
  hiddenCC: { display: 'contents' },
  textContainer: {
    maxWidth: 'fit-content',
    display: 'inline-table',
    flexFlow: 'column',
    flexBasis: '100%',
    zIndex: 2,
    textAlign: 'left',
    maxHeight: '50%',
    height: 'fit-content',
    padding: '20px 10px ',
    '&>*': {
      maxWidth: 740,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '65%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
    },
  },
  title: {
    marginBottom: 20,
    maxWidth: 650,
    direction: 'ltr',
    fontWeight: `${FONTS.WEIGHT.BOLD} !important`,
    '-webkit-text-stroke-width': '0.2px',
    '-webkit-text-stroke-color': 'lightgrey',
  },
  description: {
    direction: 'ltr',
    maxWidth: 500,
    fontSize: FONT.SIZE.M,
    [theme.breakpoints.down('md')]: {
      fontSize: FONT.SIZE.XM,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: FONT.SIZE.XS,
    },
  },
  link: {
    fontWeight: FONTS.WEIGHT.BOLD,
    fontSize: FONTS.SIZE.S,
    display: ' inline-flex',
    direction: 'ltr',
    marginTop: 20,
    textDecoration: 'none',
    '&>svg': {
      fontSize: 15,
    },
  },
  divider: {
    width: SIZES.DIVIDER.WIDTH.XL,
    height: SIZES.DIVIDER.HEIGHT.BOLD,
    backgroundColor: 'currentColor',
    marginTop: 20,
  },
  blurFilter: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backdropFilter: 'blur(8px)',
    zIndex: 1,
  },
  video: {
    alignSelf: 'center',
    width: 500,
    height: 'fit-content',
    maxWidth: '100%',
    display: 'inline-table',
    [theme.breakpoints.down('lg')]: {
      width: 450,
    },
    [theme.breakpoints.down('md')]: {
      width: 350,
    },
    [theme.breakpoints.down('sm')]: {
      width: 450,
      height: 250,
    },
    [theme.breakpoints.down('xs')]: {
      width: 380,
      height: 20,
      padding: 25,
    },
  },
  blank: {
    backgroundColor: COLORS.DARK_BLUE,
    height: '100vh',
  },
}));

const BannerSlider = ({ data }) => {
  const [sliders, setSliders] = useState([]);
  const [height, setHeight] = useState(0);
  const classes = useStyles({ height });

  useEffect(() => {
    const sliders = data.map(
      (
        {
          node: {
            title,
            description: { description },
            image,
            backgroundPosition,
            textColor,
            textPosition,
            videoLink,
            videoThumbnail,
            backgroundColor,
            linkText,
            linkTarget,
          },
        },
        i
      ) => (
        <ConditionalWrapper
          key={i}
          fluid={image ? image.fluid : ''}
          className={classes.slideImage}
          condition={image !== null}
          videoLink={videoLink}
          style={{
            backgroundColor: backgroundColor
              ? backgroundColor
              : DEFAULT_BACKGROUND_COLOR,
            backgroundPosition: backgroundPosition
              ? setBackgroundPosition(backgroundPosition)
              : '',
          }}
        >
          {videoLink && (
            <div className={classes.blurFilter} />
          )}
          <div
            className={
              videoLink
                ? classes.contentContainer
                : classes.hiddenCC
            }
          >
            <Grid
              container
              className={classes.textContainer}
              style={{
                ...setTextPosition(textPosition),
                color: textColor
                  ? textColor
                  : DEFAULT_TEXT_COLOR,
              }}
            >
              <Grid item xs={12}>
                <Header className={classes.title}>
                  {title}
                </Header>
              </Grid>
              <Grid item xs={12}>
                <p className={classes.description}>
                  {description}
                </p>
              </Grid>
              {linkText && linkTarget && (
                <Grid item xs={12}>
                  {linkTarget.charAt(0) === '/' ? (
                    <Link
                      to={linkTarget}
                      className={classes.link}
                    >
                      {linkText}
                    </Link>
                  ) : (
                    <a
                      className={classes.link}
                      href={linkTarget}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {linkText}
                      <OpenInNewIcon />
                    </a>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <div className={classes.divider} />
              </Grid>
            </Grid>
            {videoLink && (
              <div className={classes.video}>
                <Youtube
                  imgFluid={
                    videoThumbnail
                      ? videoThumbnail.fluid
                      : null
                  }
                  videoUrl={videoLink}
                />
              </div>
            )}
          </div>
        </ConditionalWrapper>
      )
    );
    const random = Math.floor(Math.random() * data.length);
    const firstSlide = sliders[0];
    sliders[0] = sliders[random];
    sliders[random] = firstSlide;
    setSliders(sliders);

    setHeight(window.innerHeight);
    window.addEventListener('resize', () =>
      setHeight(window.innerHeight)
    );
  }, []);

  const ConditionalWrapper = ({
    condition,
    videoLink,
    style,
    ...props
  }) =>
    condition ? (
      <BackgroundImage {...props} style={style} />
    ) : (
      <div
        {...props}
        style={
          videoLink
            ? {
                ...style,
                display: 'flex',
                background: `url(${getVideoThumbnail(
                  videoLink
                )} ) center/cover no-repeat`,
              }
            : { ...style }
        }
      />
    );
  ConditionalWrapper.propTypes = {
    condition: bool,
    videoLink: string,
    style: shape(),
  };

  return data ? (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay={false}
      useKeyboardArrows
    >
      {/*TODO:Replace index key*/}
      {sliders.length ? (
        sliders
      ) : (
        <div className={classes.blank} />
      )}
    </Carousel>
  ) : (
    <></>
  );
};

BannerSlider.propTypes = {
  data: arrayOf(
    shape({
      title: string,
      description: string,
      textColor: string,
      textPosition: oneOf(['Left', 'Right']),
      image: shape({ fluid: shape() }),
      backgroundColor: string,
      backgroundPosition: string,
    })
  ),
};

export default BannerSlider;
