import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import BannerSlider from '../components/Sliders/BannerSlider';
import Header from '../components/Header';
import { Grid, makeStyles } from '@material-ui/core';
import NewsCard from '../components/Cards/NewsCard';
import {
  FormattedMessage,
  navigate,
} from 'gatsby-plugin-intl';
import COLORS from '../constants/colors';
import Vow from '../components/Icons/Vow';
import DefaultButton from '../components/Buttons/DefaultButton';
import SEO from '../components/seo';
import Container from '@material-ui/core/Container';
import MetaImage from '../images/scanship-meta-image.jpg';

const useStyles = makeStyles(theme => ({
  sectionsContainer: {
    padding: '45px 205px 130px',
    backgroundColor: COLORS.WHITE,
    color: COLORS.DARK_BLUE,
    [theme.breakpoints.down('lg')]: {
      padding: '45px 60px 130px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '45px 20px 130px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '45px 0px 130px',
    },
    '&>div:nth-child(1)': {
      justifyContent: 'flex-end',
      display: 'flex',
      '& svg': {
        [theme.breakpoints.down('sm')]: {
          transform: 'scale(0.8) translateX(10%)',
          marginBottom: 60,
        },
      },
    },
  },
  aPartOf: {
    paddingBottom: 40,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
  },
  rightSideAlignment: {
    marginLeft: 'auto',
    display: 'block',
  },
}));
const Index = ({ data }) => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO
        title="Scanship | For cleaner oceans"
        description="We deliver technology that converts waste into valuable resources and generate clean energy. Cruise ships, aquaculture and a wide range of land-based industries rely on Scanship"
        keywords={['Scanship']}
        image={`.${MetaImage}`}
      />
      <BannerSlider
        data={data.allContentfulScanshipHomeBanner.edges}
      />
      <Grid container className={classes.sectionsContainer}>
        <Grid item xs={12} className={classes.aPartOf}>
          <Vow />
        </Grid>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header sub textTransform="lowercase">
                <FormattedMessage id="index.sections.0.title"></FormattedMessage>
              </Header>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {data.allContentfulScanshipNewsPost.edges.map(
                  edge => (
                    <NewsCard
                      data={edge.node}
                      key={edge.node.title}
                      xs={12}
                      sm={6}
                      md={4}
                    />
                  )
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DefaultButton
                className={classes.rightSideAlignment}
                onClick={() => navigate('/news')}
              >
                <FormattedMessage id="index.sections.0.button"></FormattedMessage>
              </DefaultButton>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Layout>
  );
};

Index.propTypes = {
  data: shape({}).isRequired,
};

export default Index;

export const query = graphql`
  query IndexQuery {
    allContentfulScanshipHomeBanner {
      edges {
        node {
          title
          description {
            description
          }
          textColor
          textPosition
          image {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          backgroundPosition
          videoLink
          videoThumbnail {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          backgroundColor
          linkText
          linkTarget
        }
      }
    }
    allContentfulScanshipNewsPost(
      limit: 3
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          publishDate(formatString: "MMMM Do, YYYY")
          metaDescription
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
