import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import Img from 'gatsby-image';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import { string, shape } from 'prop-types';
import SIZES from '../../constants/sizes';

const useStyles = makeStyles(theme => ({
  NewsCard: {
    height: 570,
    color: COLORS.DARK_BLUE,
    backgroundColor: COLORS.WHITE,
    width: 476,
    [theme.breakpoints.down('xs')]: {
      height: 500,
    },
  },
  img: {
    height: 260,
  },
  textContainer: {
    padding: '20px 0',
    '&>*': { marginBottom: 16 },
  },
  title: {
    fontSize: FONTS.SIZE.M,
    fontWeight: FONTS.WEIGHT.BOLD,
    lineClamp: 2,
    overflow: 'hidden',
    wordBreak: 'break-all',
    display: 'box',
    boxOrient: 'vertical',
    minHeight: 55,
  },
  divider: {
    backgroundColor: COLORS.BLUE,
    width: SIZES.DIVIDER.WIDTH.M,
    height: SIZES.DIVIDER.HEIGHT.REGULAR,
  },
  publishDate: {
    fontWeight: 'normal',
    fontSize: FONTS.SIZE.XS,
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'normal',
    fontSize: FONTS.SIZE.XS,
  },
}));

const NewsCard = ({ data, className, ...props }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      {...props}
      className={`${classes.NewsCard} ${className}`}
    >
      <Grid container>
        <Grid item xs={12}>
          <Link
            to={`/news/${data.slug}`}
            className={classes.link}
          >
            <Img
              fluid={data.image.fluid}
              className={classes.img}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.textContainer}
        >
          <h2 className={classes.title}>{data.title}</h2>
          <p className={classes.publishDate}>
            {data.publishDate}
          </p>
          <div className={classes.divider} />
          <Link
            to={`/news/${data.slug}`}
            className={classes.link}
          >
            <FormattedMessage id="NewsCard.link" />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

NewsCard.propTypes = {
  data: shape({
    slug: string,
    image: shape({
      fluid: shape(),
    }),
    title: string,
    publishDate: string,
  }),
  className: string,
};

export default NewsCard;
