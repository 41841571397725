const TEXT_STYLES = [
  {},
  {
    marginLeft: 'auto',
    textAlign: 'right',
    direction: 'rtl',
  },
  {
    marginTop: 'auto',
    order: 2,
  },
  {
    order: 2,
    marginLeft: 'auto',
    textAlign: 'right',
    marginTop: 'auto',
    direction: 'rtl',
  },
];

const TEXT_POSITIONS = [
  'top left',
  'top right',
  'bottom left',
  'bottom right',
];

export const setTextPosition = position => {
  let index = TEXT_POSITIONS.indexOf(
    position.toLowerCase()
  );
  index =
    index === -1
      ? Math.floor(Math.random() * TEXT_STYLES.length)
      : index;
  return TEXT_STYLES[index];
};

const BACKGROUND_POSITIONS_STYLES = [
  'left center',
  '25% center',
  'center',
  '75% center',
  'right center',
];
const BACKGROUND_POSITIONS = [
  'left edge',
  'left',
  'center',
  'right',
  'right edge',
];

export const setBackgroundPosition = position => {
  const index = BACKGROUND_POSITIONS.indexOf(
    position.toLowerCase()
  );
  return index > -1
    ? BACKGROUND_POSITIONS_STYLES[index]
    : BACKGROUND_POSITIONS_STYLES[2];
};
